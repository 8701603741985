import React from 'react';
import classNames from 'classnames/bind';

import { MentionEvent } from 'interfaces/mentionEvent';
import UserAvatar from 'components/UserAvatar';

import styles from './MentionsSuggestion.module.scss';

const cx = classNames.bind(styles);

interface MentionsSuggestionProps {
  event: MentionEvent;
  highlightedDisplay: string;
  index: number;
  isMobileOrTablet: boolean;
  isFocused: boolean;
  setCurrentlySelected: (index: number | null) => void;
  currentlySelected: number | null;
}

export const MentionsSuggestion: React.FC<MentionsSuggestionProps> = ({
  event,
  highlightedDisplay,
  index,
  isFocused,
  isMobileOrTablet,
  setCurrentlySelected,
  currentlySelected,
}) => {
  return (
    // jsx-a11y plugin requires an OnFocus function because of the onMouseOver
    // Suggestion list elements can't be focused

    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    <div
      className={cx('mentions-suggestion__container', {
        focused: isFocused === true,
        active: currentlySelected === index,
        mobile: isMobileOrTablet === true,
      })}
      data-qa="mentions-suggestion__container"
      role="listitem"
      onMouseDown={() => setCurrentlySelected(index)}
      // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
      onMouseOver={() => setCurrentlySelected(null)}
      onMouseUp={() => setCurrentlySelected(null)}
    >
      <div className={cx('mentions-suggestion__avatar-container')}>
        <UserAvatar
          avatarUrl={event.avatarUrl}
          className={cx('mentions-suggestion__avatar')}
          size="xs"
          userId={event.id}
          username={event.display}
        />
      </div>
      <div className={cx('mentions-suggestion__user')}>
        <div className={cx('mentions-suggestion__user__name')}>
          {highlightedDisplay}
        </div>
      </div>
    </div>
  );
};

export default MentionsSuggestion;
