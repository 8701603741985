import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  RefObject,
} from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import _debounce from 'lodash/debounce';

import { displayTransform, markupPattern } from 'utils/mentions';
import { useCommunityContext } from 'components/CommunityContext';
import MentionsSuggestion from 'components/Mentions/MentionsSuggestion';
import { BREAKPOINT_MD, SCREEN_PHONE } from 'utils/constants';
import { useWindowSize } from 'hooks/useWindowSize';
import {
  InjectedUsersSearchProps,
  withUsersSearch,
} from 'components/Mentions/UsersSearch';

import { MentionEvent } from 'interfaces/mentionEvent';
import {
  mapUserObject,
  createCombinedSuggestions,
} from 'components/Mentions/MentionsTextArea/helpers/mention-helpers';

import styles from './MentionsTextArea-x.module.scss';
import { HandleMentionsChange } from '../interfaces';

const cx = classNames.bind(styles);

let container;

export interface MentionsTextAreaProps extends InjectedUsersSearchProps {
  additionalClassName?: string;
  id?: string;
  isPostForm?: boolean;
  isExpanded?: boolean;
  isModal?: boolean;
  onChange: HandleMentionsChange;
  placeholder?: string;
  textAreaClassName: string;
  value: string;
  relevantMentions: MentionEvent[];
  forwardRef?: RefObject<HTMLInputElement | HTMLTextAreaElement>;
  hasAutoFocus?: boolean;
}

export const MentionsTextArea: React.FC<MentionsTextAreaProps> = ({
  id,
  additionalClassName,
  isPostForm = false,
  handleSearch,
  isExpanded = false,
  isModal,
  onChange,
  placeholder,
  textAreaClassName,
  value,
  forwardRef,
  relevantMentions = [],
  hasAutoFocus = false,
}) => {
  const [currentlySelected, setCurrentlySelected] = useState<number | null>(
    null
  );
  const [active, setActive] = useState(document.activeElement);
  const { mentionableUsers } = useCommunityContext();
  const initialUsers = mapUserObject(mentionableUsers?.edges);

  const { width } = useWindowSize();
  const { t } = useTranslation();
  const keepTextAreaExpanded = !isModal && currentlySelected !== null;
  const isPhoneScreen = width < SCREEN_PHONE;
  const isMobileOrTablet = width < BREAKPOINT_MD;
  const inputRef =
    forwardRef || useRef<HTMLTextAreaElement | HTMLInputElement>(null);
  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.platform) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

  // postform modal size ( 560px ) - 51(space around either side). There are some resolutions where the
  // the screen is smaller than 560 wide, but we haven't swapped to phone view yet. Pick the smaller of the two numbers.
  const tabletScreenSuggestionListWidth = Math.min(509, width - 51);

  // 17px on either side of postform textArea for phone screens
  const phoneScreenSuggestionListWidth = width - 34;
  const calculatedSuggestionListWidth = isPhoneScreen
    ? phoneScreenSuggestionListWidth
    : tabletScreenSuggestionListWidth;

  let leftStyle = isPhoneScreen ? '16px' : null;
  // if not on the postform we want the modal at left:0
  leftStyle = isPostForm ? leftStyle : '0';

  useEffect(() => {
    if (inputRef?.current && hasAutoFocus) {
      inputRef.current.focus();
      setActive(inputRef.current);
    }
  }, [inputRef]);

  const combinedUsers = createCombinedSuggestions(
    initialUsers,
    relevantMentions
  );

  // if forwardRef exists, use that, otherwise use inputRef ( comment/reply section modals )
  const mentionsTextAreaRef = forwardRef || inputRef;

  useEffect(() => {
    const { current: ref } = mentionsTextAreaRef;

    ref?.addEventListener('focusin', handleFocusIn);
    return () => {
      ref?.removeEventListener('focusin', handleFocusIn);
    };
  }, []);

  const handleFocusIn = () => {
    setActive(document.activeElement);
  };

  useEffect(() => {
    // If the user touches a mentions input on any iOS device, scrolling is permanently disabled for the entire app
    // so until we have a better fix for that, don't run any of this code
    // if (active?.nodeName === 'TEXTAREA' && mentionsTextAreaRef && isModal) {
    //   disableBodyScroll(mentionsTextAreaRef.current);
    // } else {
    //   enableBodyScroll(mentionsTextAreaRef.current);
    // }
  }, [active]);

  const mentionsSuggestionComponent = (
    event,
    _search,
    highlightedDisplay,
    index,
    focused
  ) => {
    return (
      <>
        <MentionsSuggestion
          currentlySelected={currentlySelected}
          event={event}
          highlightedDisplay={highlightedDisplay}
          index={index}
          isFocused={focused}
          isMobileOrTablet={isMobileOrTablet}
          setCurrentlySelected={setCurrentlySelected}
        />
      </>
    );
  };

  const mobileStyles = {
    maxHeight: '300px',
    position: 'fixed',
    // if not on the postform, the suggestionList modal takes up 100% of the width.
    width: isPostForm ? `${calculatedSuggestionListWidth}px` : '100%',
    left: leftStyle,
    border: '0.5px solid #CACCD6',
    borderTop: '0',
    backgroundColor: 'transparent',
    paddingTop: isPhoneScreen && isPostForm && isIOS ? '60px' : '10px',
  };

  const onSearch = useCallback(
    _debounce(async (search: string, callback: (users) => void) => {
      if (!search) {
        callback(combinedUsers);
      } else {
        const users: MentionEvent[] | void = await handleSearch(search);
        callback(users);
      }
    }, 250),
    []
  );

  // shorten to only display 3 on mobile screens
  const mobileSuggestionListMaxHeight = isPhoneScreen ? '120px' : '300px';

  // shorten to fit within a single screen - no need to scroll
  const mobileMentionsTextAreaHeight = isPostForm ? '100px' : '190px';

  return (
    <>
      <div
        aria-label={t(
          'components-mentions-keyboard_navigation_aria_label',
          'Mention someone. Press the up/down arrow keys to navigate. Press space/enter to select a name.'
        )}
        aria-live="polite"
        aria-modal="true"
        data-qa="suggestion-portal"
        id="suggestion-portal"
        ref={(el) => {
          container = el;
        }}
        role="dialog"
      />
      <div
        className={cx('mentions__container')}
        data-qa="mentions__container"
        id={id}
      >
        <MentionsInput
          className={cx(textAreaClassName, additionalClassName, {
            expanded: keepTextAreaExpanded || isExpanded || value.length,
          })}
          data-qa="mentions-input"
          inputRef={inputRef}
          placeholder={placeholder}
          style={{
            suggestions: {
              zIndex: 10000,
              boxShadow: '0 12px 12px 0 rgba(0, 0, 0, 0.25)',
              ...(isMobileOrTablet ? mobileStyles : null),
              list: {
                maxHeight: isMobileOrTablet
                  ? mobileSuggestionListMaxHeight
                  : null,
                overflowY: isMobileOrTablet ? 'scroll' : null,
              },
            },
            '&multiLine': {
              highlighter: {
                maxHeight: isMobileOrTablet
                  ? mobileMentionsTextAreaHeight
                  : null,
                overflowY: 'hidden',
              },
            },
          }}
          suggestionsPortalHost={container}
          value={value}
          allowSpaceInQuery
          allowSuggestionsAboveCursor
          onChange={onChange}
        >
          <Mention
            a11ySuggestionsListLabel={t('Suggested mention')}
            className={cx('mentions__highlight')}
            // Data needs to be either an array of users,
            // or a search function that is provided (search, callback)
            // that provides a list of users via callback
            data={onSearch}
            displayTransform={displayTransform}
            markup={markupPattern}
            renderSuggestion={mentionsSuggestionComponent}
            trigger="@"
            appendSpaceOnAdd
            onAdd={() => setCurrentlySelected(null)}
          />
        </MentionsInput>
      </div>
    </>
  );
};

export default withUsersSearch(MentionsTextArea);
