import React from 'react';
import { useQuery } from '@apollo/client';

import { useCommunityContext } from 'components/CommunityContext';
import { MentionEvent } from 'interfaces/mentionEvent';

import { USERS_QUERY } from 'schema/User/queries';
import { mapUserObject } from 'components/Mentions/MentionsTextArea/helpers/mention-helpers';

export interface InjectedUsersSearchProps {
  handleSearch: (search: string) => Promise<MentionEvent[]>;
}

export const withUsersSearch = <P extends InjectedUsersSearchProps>(
  BaseComponent: React.FC<P>
): React.FC<Omit<P, keyof InjectedUsersSearchProps>> =>
  function WithUsersSearch({ ...props }) {
    const { community } = useCommunityContext();

    const handleSearch = async (search: string) => {
      const variables = {
        searchTerm: search,
        communityId: community.id,
      };

      const res = await fetchMore({ variables });
      return mapUserObject(res?.data?.community?.mentionableUsers?.edges);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fetchUsers = (communityId: string): any => {
      // Skip the first load of this query, but establish a fetchMore.
      // This is because of useLazyQuery's lack of return
      // https://github.com/apollographql/react-apollo/issues/3499
      // This query will only be called when the fetchMore is used in the handleSearch function
      const { fetchMore } = useQuery(USERS_QUERY, {
        variables: {
          communityId,
        },
        notifyOnNetworkStatusChange: true,
        skip: true,
      });

      return fetchMore;
    };

    const fetchMore = fetchUsers(community.id);

    return <BaseComponent {...(props as P)} handleSearch={handleSearch} />;
  };

export default withUsersSearch;
