import { MentionEvent } from 'interfaces/mentionEvent';
import { ElasticSearchUserResult } from 'interfaces/elasticSearchUserResult';
import _uniqBy from 'lodash/uniqBy';

export const mapUserObject = (
  userData: ElasticSearchUserResult[]
): MentionEvent[] => {
  const users = userData || [];
  return users
    .filter((userNode) => {
      const user = userNode?.node?.user;
      return user?.fullName && user?.id;
    })
    .map(({ node: mentionableUser }) => ({
      display: mentionableUser?.user?.fullName,
      id: mentionableUser?.user?.id,
      avatarUrl: mentionableUser?.user?.profile?.avatarUrl || '',
    }));
};

export const createCombinedSuggestions = (
  initial: MentionEvent[],
  relevant: MentionEvent[]
): MentionEvent[] => {
  const maxSize = 10;
  const combinedList = _uniqBy(relevant.concat(initial), 'id');
  return combinedList.splice(0, maxSize);
};
